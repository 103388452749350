import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-services"} />
		<Helmet>
			<title>
				Наші послуги в ArtVibes Academy
			</title>
			<meta name={"description"} content={"Відкрийте двері у світ мистецтва – досліджуйте, навчайтесь, творіть разом з нами"} />
			<meta property={"og:title"} content={"Наші послуги в ArtVibes Academy"} />
			<meta property={"og:description"} content={"Відкрийте двері у світ мистецтва – досліджуйте, навчайтесь, творіть разом з нами"} />
			<meta property={"og:image"} content={"https://ymplex.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ymplex.com/img/2602414.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ymplex.com/img/2602414.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ymplex.com/img/2602414.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ymplex.com/img/2602414.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ymplex.com/img/2602414.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ymplex.com/img/2602414.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://ymplex.com/img/4.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="360px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					ArtVibes
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Наші послуги
				</Text>
				<Text font="--base">
					У ArtVibes Academy ми пропонуємо широкий спектр онлайн-курсів та ресурсів, призначених для розвитку ваших художніх навичок та талантів. Наша програма охоплює різноманітні аспекти мистецтва, від базових технік живопису до складних методів та стилів.				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-29">
			<Override
				slot="SectionContent"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
				flex-wrap="wrap"
				md-flex-direction="column"
				sm-min-width="280px"
			/>
			<Box width="20%" sm-width="100%" md-margin="0px 0px 36px 0px">
				<Text margin="0px 0px 0px 0px" font="--lead" color="--dark" sm-margin="0px 0px 20px 0px">
					Ми пропонуємо:
					<br />
				</Text>
			</Box>
			<Box width="80%" sm-width="100%" md-width="100%">
				<Box
					border-width="0px 0px 1px 0px"
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
				>
					<Text color="--dark" letter-spacing="-.7px" margin="0px 0px 8px 0px" font="--headline3">
						Основи мистецтва:
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Вивчіть базові принципи та техніки, що лежать в основі всіх видів мистецтва.
					</Text>
				</Box>
				<Box
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Курси за техніками:
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Акварель, олійний живопис, пастель – оберіть те, що вам найбільше до душі, і досліджуйте його глибини.
					</Text>
				</Box>
				<Box
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px">
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Тематичні майстер-класи:
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Від пейзажів до портретів, від абстракції до натюрморту – ви зможете знайти курс, який відповідає саме вашим інтересам.
					</Text>
				</Box>
				<Box width="100%" border-color="--color-lightD2" margin="0px 0px 0px 0px">
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Індивідуальні консультації:
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Отримайте персоналізовану увагу та поради від досвідчених художників та викладачів.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-3">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="65%"
				align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				justify-content="center"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					height="auto"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://ymplex.com/img/5.jpg"
						object-fit="cover"
						left={0}
						top={0}
						right={0}
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://ymplex.com/img/6.jpg"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Якщо ви готові розпочати свою подорож у світі мистецтва або підняти свої навички на новий рівень, ArtVibes Academy чекає на вас. Зв'яжіться з нами за додатковою інформацією та дізнайтесь, як ми можемо допомогти вам розкрити ваш потенціал та знайти свій унікальний шлях у мистецтві.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});